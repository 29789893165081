<template>
  <div>
    <header class="header main-wrapper" id="pageTop">
      <!-- Top Color Bar -->
      <div class="color-bars">
        <div class="container-fluid">
          <div class="row">
            <div class="col color-bar bg-warning d-none d-md-block"></div>
            <div class="col color-bar bg-success d-none d-md-block"></div>
            <div class="col color-bar bg-danger d-none d-md-block"></div>
            <div class="col color-bar bg-info d-none d-md-block"></div>
            <div class="col color-bar bg-purple d-none d-md-block"></div>
            <div class="col color-bar bg-pink d-none d-md-block"></div>
            <div class="col color-bar bg-warning"></div>
            <div class="col color-bar bg-success"></div>
            <div class="col color-bar bg-danger"></div>
            <div class="col color-bar bg-info"></div>
            <div class="col color-bar bg-purple"></div>
            <div class="col color-bar bg-pink"></div>
          </div>
        </div>
      </div>

      <!-- Top Bar-->
      <!-- d-none d-md-block -->
      <div class="bg-stone top-bar d-none d-md-block">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 d-none d-lg-block">
              <ul
                class="list-inline d-flex justify-content-xl-start align-items-center h-100 mb-0"
              >
                <li>
                  <time
                    class="mr-lg-4 mr-xl-6 text-white opacity-80"
                    :datetime="`${year}-${month}-${day}`"
                    >{{ month }} {{ day }}, {{ year }}</time
                  >
                </li>
              </ul>
            </div>

            <div class="col-lg-7">
              <ul
                class="list-inline d-flex mb-0 justify-content-xl-end justify-content-center align-items-center mr-xl-2"
              >
                <li class="text-white mr-md-3 mr-lg-2 mr-xl-5">
                  <img src="assets/img/coco_search.png" class="coco__mobile" />
                </li>

                <li class="mr-3 mr-md-3 mr-lg-2 mr-xl-5">
                  <form action="" class="d-flex align-items-center">
                    <div
                      class="input-group shadow-light rounded-sm input-addon"
                    >
                      <input
                        type="text"
                        class="form-control py-4"
                        placeholder="search..."
                        aria-describedby="basic-addon21"
                        v-model="searchText"
                      />
                      <div class="input-group-append" @click="search">
                        <div class="input-group-text bg-danger">
                          <i
                            class="fa fa-search text-white"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </li>
                <li class="text-white mr-md-3 mr-lg-2 mr-xl-5">
                  <a
                    class="text-white font-weight-medium opacity-80"
                    href="javascript:void(0)"
                    @click="handleMemberLink"
                  >
                    <span class="rounded-sm bg-purple icon-small icon-badge ">
                      <i
                        class="fa fa-user text-white"
                        aria-hidden="true"
                        style="margin-top: 6px;"
                      ></i>
                    </span>
                  </a>
                </li>

                <li class="cart-dropdown d-none d-md-block">
                  <div
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-display="static"
                  >
                    <a href="javascript:void(0)">
                      <span
                        class="rounded-sm bg-info icon-small icon-badge d-none close-icon"
                      >
                        <i
                          class="fa fa-close text-white"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span
                        class="rounded-sm bg-info icon-small icon-badge shopping-icon"
                      >
                        <i
                          class="fa fa-binoculars text-white"
                          aria-hidden="true"
                          style="margin-top: 6px;"
                        ></i>
                      </span>
                    </a>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="bg-white list-unstyled">
                      <li class="d-flex align-items-center">
                        <i
                          class="fa fa-binoculars font-size-20 mr-3"
                          aria-hidden="true"
                        ></i>
                        <h3 class="text-capitalize font-weight-bold mb-0">
                          依財商年齡瀏覽
                        </h3>
                      </li>
                      <hr />
                      <li>
                        <a
                          href="javascript:void(0)"
                          style="display: block;"
                          @click="
                            checkGradeIsAvaliable(
                              $event,
                              1,
                              '/fqgame/kindergarten'
                            )
                          "
                        >
                          <div class="media">
                            <div
                              class="media-icon-large bg-success rounded shadow-sm mr-4"
                            >
                              <i class="fa fa-leaf" aria-hidden="true"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-success text-truncate">
                                理財幼幼級
                              </h3>
                              <p>適合初學者的你</p>
                            </div>
                          </div>
                        </a>
                        <hr />
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          style="display: block;"
                          @click="
                            checkGradeIsAvaliable(
                              $event,
                              2,
                              '/fqgame/elementary'
                            )
                          "
                        >
                          <div class="media">
                            <div
                              class="media-icon-large bg-info rounded shadow-sm mr-4"
                            >
                              <i class="fa fa-futbol-o" aria-hidden="true"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-info text-truncate">
                                理財國小級
                              </h3>
                              <p>適合有點基礎的你</p>
                            </div>
                          </div>
                        </a>
                        <hr />
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          style="display: block;"
                          @click="
                            checkGradeIsAvaliable($event, 3, '/fqgame/junior')
                          "
                        >
                          <div class="media">
                            <div
                              class="media-icon-large bg-danger rounded shadow-sm mr-4"
                            >
                              <i class="fa fa-gamepad" aria-hidden="true"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-danger text-truncate">
                                理財國中級
                              </h3>
                              <p>適合進階的你</p>
                            </div>
                          </div>
                        </a>
                        <hr />
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          style="display: block;"
                          @click="
                            checkGradeIsAvaliable($event, 4, '/fqgame/senior')
                          "
                        >
                          <div class="media">
                            <div
                              class="media-icon-large bg-warning rounded shadow-sm mr-4"
                            >
                              <i
                                class="fa fa-graduation-cap"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-warning text-truncate">
                                理財高中級
                              </h3>
                              <p>適合想挑戰的你</p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          style="display: block;"
                          @click="
                            checkGradeIsAvaliable(
                              $event,
                              -1,
                              '/fqgame/108course'
                            )
                          "
                        >
                          <div class="media">
                            <div
                              class="media-icon-large bg-warning rounded shadow-sm mr-4"
                            >
                              <i
                                class="fa fa-graduation-cap"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-warning text-truncate">
                                108課綱
                              </h3>
                              <p>適合想挑戰的你</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Navbar -->
      <nav class="navbar navbar-expand-md navbar-scrollUp navbar-white">
        <div class="container flex-nowrap">
          <a href="/" class="navbar-brand mr-0 mr-sm-auto">
            <img
              class="header__logo d-inline-block"
              src="@/assets/img/logo-school.png"
              alt="logo"
            />
          </a>

          <div class=" mr-1 mr-sm-5 d-md-none">
            <img src="assets/img/coco_search.png" class="coco__mobile">
          </div>

          <!-- cart-dropdown -->
          <div class="dropdown cart-dropdown ml-auto mr-1 mr-sm-5 d-md-none">
            <div
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <a href="javascript:void(0)">
                <span
                  class="rounded-sm bg-info icon-small icon-badge d-none close-icon"
                >
                  <i class="fa fa-close text-white" aria-hidden="true"></i>
                </span>
                <span
                  class="rounded-sm bg-info icon-small icon-badge shopping-icon"
                >
                  <i
                    class="fa fa-binoculars text-white"
                    aria-hidden="true"
                    style="margin-top: 6px;"
                  ></i>
                </span>
              </a>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="bg-white list-unstyled">
                <li class="d-flex align-items-center">
                  <i
                    class="fa fa-binoculars font-size-20 mr-3"
                    aria-hidden="true"
                  ></i>
                  <h3 class="text-capitalize font-weight-bold mb-0">
                    依財商年齡瀏覽
                  </h3>
                </li>
                <hr />
                <li>
                  <a
                    href="javascript:void(0)"
                    style="display: block;"
                    @click="
                      checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')
                    "
                  >
                    <div class="media">
                      <div
                        class="media-icon-large bg-success rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-leaf" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-success text-truncate">理財幼幼級</h3>
                        <p>適合初學者的你</p>
                      </div>
                    </div>
                  </a>
                  <hr />
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style="display: block;"
                    @click="
                      checkGradeIsAvaliable($event, 2, '/fqgame/elementary')
                    "
                  >
                    <div class="media">
                      <div
                        class="media-icon-large bg-info rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-futbol-o" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-info text-truncate">理財國小級</h3>
                        <p>適合有點基礎的你</p>
                      </div>
                    </div>
                  </a>
                  <hr />
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style="display: block;"
                    @click="checkGradeIsAvaliable($event, 3, '/fqgame/junior')"
                  >
                    <div class="media">
                      <div
                        class="media-icon-large bg-danger rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-gamepad" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-danger text-truncate">理財國中級</h3>
                        <p>適合進階的你</p>
                      </div>
                    </div>
                  </a>
                  <hr />
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style="display: block;"
                    @click="checkGradeIsAvaliable($event, 4, '/fqgame/senior')"
                  >
                    <div class="media">
                      <div
                        class="media-icon-large bg-warning rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-warning text-truncate">理財高中級</h3>
                        <p>適合想挑戰的你</p>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style="display: block;"
                    @click="checkGradeIsAvaliable($event, -1, '/fqgame/108course')"
                  >
                    <div class="media">
                      <div
                        class="media-icon-large bg-warning rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-warning text-truncate">108課綱</h3>
                        <p>適合想挑戰的你</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class=" mr-5 d-md-none">
            <a
              class="text-white font-weight-medium opacity-80"
              href="javascript:void(0)"
              @click="handleMemberLink"
            >
              <span class="rounded-sm bg-purple icon-small icon-badge ">
                <i
                  class="fa fa-user text-white"
                  aria-hidden="true"
                  style="margin-top: 6px;"
                ></i>
              </span>
            </a>
          </div>

          <button
            id="navbar-collapse-button"
            class="navbar-toggler py-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa fa-bars"></i>
          </button>

          <div class="collapse navbar-collapse" id="navbarContent">
            <ul class="navbar-nav ml-lg-auto">
              <li class="nav-item dropdown bg-warning">
                <router-link
                  to="/fqgame"
                  class="nav-link dropdown-toggle"
                  href="/fqgame"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    class="fa fa-graduation-cap nav-icon"
                    aria-hidden="true"
                  ></i>
                  <span>FQ大冒險</span>
                </router-link>
                <a
                  href="javascript:void(0)"
                  class="click-preventer"
                  data-toggle="dropdown"
                ></a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')
                      "
                      >理財幼幼級</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        checkGradeIsAvaliable($event, 2, '/fqgame/elementary')
                      "
                      >理財國小級</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        checkGradeIsAvaliable($event, 3, '/fqgame/junior')
                      "
                      >理財國中級</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        checkGradeIsAvaliable($event, 4, '/fqgame/senior')
                      "
                      >理財高中級</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        checkGradeIsAvaliable($event, -1, '/fqgame/108course')
                      "
                      >108課綱</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-danger">
                <router-link
                  to="/topic"
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i class="fa fa-list-ul nav-icon" aria-hidden="true"></i>
                  <span>主題區</span>
                </router-link>
                <a
                  href="javascript:void(0)"
                  class="click-preventer"
                  data-toggle="dropdown"
                ></a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link to="/topic/management" class="dropdown-item"
                      >我為何要學理財</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/topic/track-expenses"
                      class="dropdown-item"
                      >記帳教我的事</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/pocket-money" class="dropdown-item"
                      >零用錢三堂課</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/currency" class="dropdown-item"
                      >認識貨幣跟外匯</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/investment" class="dropdown-item"
                      >認識投資商品</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/balance-sheet" class="dropdown-item"
                      >人生資產負債表</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/board-game" class="dropdown-item"
                      >桌遊學理財</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/column" class="dropdown-item"
                      >專家觀點</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/topic/majashow" class="dropdown-item"
                      >小馬哈影音秀</router-link
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-success">
                <router-link
                  to="/learning-sheet"
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-file-text-o nav-icon" aria-hidden="true"></i>
                  <span>爸媽請進</span>
                </router-link>
                <a
                  href="javascript:void(0)"
                  class="click-preventer"
                  data-toggle="dropdown"
                ></a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callLearningSheetAnchor(
                          $route.query,
                          '/learning-sheet?anchor=levelA'
                        )
                      "
                      >理財幼幼級練習單</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callLearningSheetAnchor(
                          $route.query,
                          '/learning-sheet?anchor=levelB'
                        )
                      "
                      >理財國小級練習單</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callLearningSheetAnchor(
                          $route.query,
                          '/learning-sheet?anchor=levelC'
                        )
                      "
                      >理財國中級練習單</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callLearningSheetAnchor(
                          $route.query,
                          '/learning-sheet?anchor=levelD'
                        )
                      "
                      >理財高中級練習單</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callLearningSheetAnchor(
                          $route.query,
                          '/learning-sheet?anchor=levelE'
                        )
                      "
                      >108 課綱</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-info">
                <router-link
                  to="/course"
                  class="nav-link dropdown-toggle"
                  id="stores"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    class="fa fa-pencil-square-o nav-icon"
                    aria-hidden="true"
                  ></i>
                  <span>理財課程</span>
                </router-link>
                <a
                  href="javascript:void(0)"
                  class="click-preventer"
                  data-toggle="dropdown"
                ></a>
                <ul class="dropdown-menu" aria-labelledby="stores">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callCourseAnchor(
                          $route.query,
                          '/course?anchor=online',
                          false
                        )
                      "
                      >線上課程</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click.prevent="
                        callCourseAnchor(
                          $route.query,
                          '/course?anchor=offline',
                          true
                        )
                      "
                      >線下課程</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-purple">
                <router-link
                  to="/rank"
                  class="nav-link dropdown-toggle"
                  id="stores"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-trophy nav-icon" aria-hidden="true"></i>
                  <span>排行榜</span>
                </router-link>
                <a
                  href="javascript:void(0)"
                  class="click-preventer"
                  data-toggle="dropdown"
                ></a>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="stores"
                >
                  <li>
                    <a href="/rank?q=kindergarten" class="dropdown-item"
                      >幼幼王排行榜</a
                    >
                  </li>
                  <li>
                    <a href="/rank?q=elementary" class="dropdown-item"
                      >國小王排行榜</a
                    >
                  </li>
                  <li>
                    <a href="/rank?q=secondary" class="dropdown-item"
                      >國中王排行榜</a
                    >
                  </li>
                  <li>
                    <a href="/rank?q=high" class="dropdown-item"
                      >高中王排行榜</a
                    >
                  </li>
                  <li>
                    <a href="/rank?q=point" class="dropdown-item"
                      >點數王排行榜</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-pink">
                <router-link
                  to="/activity"
                  class="nav-link dropdown-toggle"
                  id="stores"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-picture-o nav-icon" aria-hidden="true"></i>
                  <span>活動實錄</span>
                </router-link>
                <a
                  href="javascript:void(0)"
                  class="click-preventer"
                  data-toggle="dropdown"
                ></a>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="stores"
                >
                  <li>
                    <router-link to="/activity/camp" class="dropdown-item"
                      >營隊</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/activity/course" class="dropdown-item"
                      >課程</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/activity/lecture" class="dropdown-item"
                      >企業講座</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/activity/welfare" class="dropdown-item"
                      >公益團體</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
/* global $ */
import tokenGenerator from '@/tokenGenerator.js'
export default {
  data () {
    return {
      searchText: '',
      year: '',
      day: '',
      month: ''
    }
  },
  created () {
    const vm = this
    vm.year = new Date().getFullYear()
    switch (new Date().getMonth() + 1) {
      case 1:
        vm.month = 'Jan'
        break
      case 2:
        vm.month = 'Feb'
        break
      case 3:
        vm.month = 'Mar'
        break
      case 4:
        vm.month = 'Apr'
        break
      case 5:
        vm.month = 'May'
        break
      case 6:
        vm.month = 'June'
        break
      case 7:
        vm.month = 'July'
        break
      case 8:
        vm.month = 'Aug'
        break
      case 9:
        vm.month = 'Sep'
        break
      case 10:
        vm.month = 'Oct'
        break
      case 11:
        vm.month = 'Nov'
        break
      case 12:
        vm.month = 'Dec'
        break
    }
    vm.day = new Date().getDate()
    console.log(vm.year, vm.month, vm.day)
  },
  mounted () {
    require('@/assets/js/base.js')
    if ($(window).width() > 991) {
      $('.navbar-expand-lg .navbar-nav .dropdown').hover(
        function () {
          $(this)
            .addClass('')
            .find('.dropdown-menu')
            .addClass('show')
        },
        function () {
          $(this)
            .find('.dropdown-menu')
            .removeClass('show')
        }
      )
    }

    if ($(window).width() > 767) {
      $('.navbar-expand-md .navbar-nav .dropdown').hover(
        function () {
          $(this)
            .addClass('')
            .find('.dropdown-menu')
            .addClass('show')
        },
        function () {
          $(this)
            .find('.dropdown-menu')
            .removeClass('show')
        }
      )
    }

    // const clearShow = () => {
    //   const showedNavbar = document.querySelector('#navbarContent.show')
    //   if (showedNavbar) {
    //     showedNavbar.classList.remove('show')
    //     document.removeEventListener('scroll', clearShow)
    //   }
    // }

    document.addEventListener('scroll', this.clearShow)
  },
  watch: {
    $route (to, from) {
      $('.navbar-collapse.show').removeClass('show')
      $('.nav-item.show').removeClass('show')
    }
  },
  methods: {
    checkGradeIsAvaliable (e, grade, link) {
      /**
       * 點擊任一連結時打 check api，如果未登入就導向登入頁
       * 如果已登入，確認是否可玩該區塊遊戲
       * 如果可以就 push 進去
       * 如果不行就維持在此頁
       */
      if (this.$route.fullPath.includes(link)) return null
      const memberToken = localStorage.getItem('memberToken')
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      if (!memberToken) {
        alert('尚未登入，請先登入之後才能開始遊戲~')
        vm.$router.push('/login')
        return null
      }
      vm.axios
        .post(api, {
          memberToken,
          token: tokenGenerator()
        })
        .then(res => {
          const userInfo = res.data.content
          if (userInfo.grade < grade) {
            alert('年級不符，請先玩其他年級的遊戲喔')
          } else {
            vm.$router.push(link)
          }
        })
    },
    search () {
      const vm = this

      if (vm.searchText === '') {
        return false
      }

      vm.$router.push(`/search?q=${vm.searchText}`)
    },
    handleMemberLink () {
      const memberToken = localStorage.getItem('memberToken')
      if (memberToken) {
        if (this.$route.fullPath !== '/member') {
          this.$router.push('/member')
        }
      } else {
        this.$router.push('/login')
      }
    },
    callCourseAnchor (query, goingPath, coveredByNav) {
      console.log({ query, goingPath, coveredByNav })
      // 處理非 /course 頁點擊連結時
      if (this.$route.path !== '/course') {
        this.$router.push(goingPath)
        return null
      }
      const paths = ['offline', 'online']
      // 當目前完整連結等於要去的完整連結
      if (this.$route.fullPath === goingPath) {
        // 處理相同頁面狀態下的捲動
        const target = document.querySelector(`#${this.$route.query.anchor}`)
        if (target) {
          const top = target.getBoundingClientRect().top
          const navbarHeight = coveredByNav
            ? document.querySelector('nav.navbar').getBoundingClientRect()
              .height
            : 0
          window.scrollTo(0, top - navbarHeight)
        }
        // 當目前連結相同，但 ?anchor 的值不同時
      } else if (
        this.$route.fullPath === '/course' ||
        paths.includes(query.anchor)
      ) {
        // 這一行只有做改變錨點，下面的事情都一樣
        this.$router.push(goingPath).then(() => {
          const target = document.querySelector(`#${this.$route.query.anchor}`)
          console.log(target)
          if (target) {
            const top = target.getBoundingClientRect().top
            const navbarHeight = coveredByNav
              ? document.querySelector('nav.navbar').getBoundingClientRect()
                .height
              : 0
            window.scrollTo(0, top - navbarHeight)
          }
        })
      }
    },
    callLearningSheetAnchor (query, goingPath) {
      // 處理非 /learning-sheet 頁點擊連結時
      if (this.$route.path !== '/learning-sheet') {
        this.$router.push(goingPath)
        return null
      }
      const paths = ['levelA', 'levelB', 'levelC', 'levelD', 'levelE']
      // 當目前完整連結等於要去的完整連結
      if (this.$route.fullPath === goingPath) {
        // 處理相同頁面狀態下的捲動
        const target = document.querySelector(`#${this.$route.query.anchor}`)
        if (target) {
          const top = target.getBoundingClientRect().top
          const navbarHeight = document
            .querySelector('nav.navbar')
            .getBoundingClientRect().height
          window.scrollTo(0, top - navbarHeight)
        }
        // 當目前連結相同，但 ?anchor 的值不同時
      } else if (
        this.$route.fullPath === '/learning-sheet' ||
        paths.includes(query.anchor)
      ) {
        // 這一行只有做改變錨點，下面的事情都一樣
        this.$router.push(goingPath).then(() => {
          const target = document.querySelector(`#${this.$route.query.anchor}`)
          if (target) {
            const top = target.getBoundingClientRect().top
            const navbarHeight = document
              .querySelector('nav.navbar')
              .getBoundingClientRect().height
            window.scrollTo(0, top - navbarHeight)
          }
        })
      }
    },
    clearShow () {
      const showedNavbar = document.querySelector('#navbarContent.show')
      console.log('hi')
      if (showedNavbar) {
        console.log('fix ok')
        showedNavbar.classList.remove('show')
        // document.removeEventListener('scroll', this.clearShow)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/kidz.css";

.dropdown-menu {
  color: red;
  display: none;
  &.show {
    display: block;
  }
}
</style>
