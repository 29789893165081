<template>
  <div>
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Base',
  components: { Navbar, Footer },
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
